<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 标题 -->
					<el-form-item label="标题名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 说明 -->
					<el-form-item label="问卷说明" prop="desc">
						<div class="shuoming">
							<el-input type="textarea" v-model="form.desc" placeholder="请填写说明"></el-input>
						</div>
					</el-form-item>
					<!-- 答题时间 -->
					<el-form-item label="答题时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetimerange" range-separator="截至"
								start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 展示专栏 -->
					<el-form-item label="展示专栏" prop="sex">
						<el-select v-model="form.sex" placeholder="请选择展示专栏" size="medium">
							<el-option label="直属机关工会" value="1"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="基层工会" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 可见组织 -->
					<el-form-item label="可见组织" prop="Mars">
						<el-select v-model="form.Mars" placeholder="请选择组织名称" size="medium" @click="multiple"
							@change="numerical" />
					</el-form-item>
					<!-- 是否启用 -->
					<el-form-item label="是否发布" prop="enable">
						<el-select v-model="form.enable" placeholder="请选择是否发布" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 问卷模式 -->
					<el-form-item label="问卷模式" prop="pattern">
						<el-select v-model="form.pattern" placeholder="请选择问卷模式" size="medium">
							<el-option label="收集意见" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="答题模式" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 时间限制 -->
					<el-form-item label="时间限制" prop="isSetTime">
						<el-select v-model="form.isSetTime" placeholder="请选择问卷模式" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 答题时间 -->
					<el-form-item label="答题时间" prop="setTime" v-if="form.isSetTime==1">
						<div class="aaaa">
							<el-input v-model="form.setTime" size="large" placeholder="请填写答题时间(分钟)"></el-input>
						</div>
						<!-- <div class="referrals">
							（提示：免费则输入0。）
						</div> -->
					</el-form-item>
					<!-- 是否与健步配合答题 -->
					<el-form-item label="健步配合" prop="strides">
						<el-select v-model="form.strides" placeholder="请选择是否与健步配合答题" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 达标步数 -->
					<el-form-item label="达标步数" prop="reach">
						<div class="aaaass">
							<el-input v-model="form.reach" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写达标步数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 达标题数 -->
					<el-form-item label="达标题数" prop="problem">
						<div class="aaaass">
							<el-input v-model="form.problem" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写达标题数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 单次题数 -->
					<el-form-item label="单次题数" prop="einmal">
						<div class="aaaass">
							<el-input v-model="form.einmal" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写单次题数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 问题答尽后随机出题 -->
					<el-form-item label="随机出题" prop="random">
						<el-select v-model="form.random" placeholder="问题答尽后是否随机出题" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 限制每日答题次数 -->
					<el-form-item label="答题次数" prop="restrictive">
						<el-select v-model="form.restrictive" placeholder="是否限制答题次数" size="medium">
							<el-option label="按每日限制" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="按总次数限制" value="3"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="不限制" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 限制次数 -->
					<el-form-item label="限制次数" prop="frequency">
						<div class="aaaass">
							<el-input v-model="form.frequency" onkeyup="value=value.replace(/[^\d]/g,'')" type="number"
								placeholder="请填写答题次数" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 小程序端展示排行 -->
					<el-form-item label="展示排行" prop="exhibition">
						<el-select v-model="form.exhibition" placeholder="是否展示小程序端排行" size="medium">
							<el-option label="公开展示" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="管理员可见" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="全部不展示" value="3" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 小程序端展示历史记录 -->
					<el-form-item label="展示记录" prop="record">
						<el-select v-model="form.record" placeholder="是否展示小程序端历史记录" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 标题图片 -->
					<el-form-item label="主题图片" prop="breviary">
						<el-upload action="/admin/ques/saveImage" list-type="picture-card" limit="2" ref="qingkong"
							v-model="form.breviary" :auto-upload="true" :on-change="change" :on-error="bedefeated"
							:on-success="succ" :on-remove="Remove" :class="styA==0?'styB':'styC'"
							:before-upload="beforeImageUpload" :before-remove="beforeremove" :on-preview="onpreview"
							accept=".jpeg,.png,.jpg,.bmp,.gif" :headers="header" name="imageFile">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽700px、高400px。)
						</div>
					</el-form-item>
					<!-- 答题问卷 -->
					<div class="sheet">
						<div class="dati">问卷</div>
						<div class="lation">
							<div class="twist"><el-button type="primary"
									style="width: 90px;height: 30px;font-size: 16px;" size="small"
									@click="copyreader"><el-icon class="cancel">
										<CirclePlus />
									</el-icon>新增</el-button></div>
							<div class="shuomings" v-for="(item,index) in topic" :key="index">
								<el-form-item label="问卷题目">
									<div class="circu">
										<el-input v-model="item.name" placeholder="请填写问卷题目" size="large"></el-input>
										<el-button size="default" @click="expurgate(index)" class="red"
											:disabled="forbidden"><el-icon class="cancel">
												<Delete />
											</el-icon>删除</el-button>
									</div>
								</el-form-item>

								<el-form-item label="问卷类型">
									<el-radio-group v-model="item.type" size="large" @change="survey"
										@click="dianji(index)">
										<el-radio label="1">单选题</el-radio>
										<el-radio label="2">多选题</el-radio>
										<el-radio label="3">简答题</el-radio>
									</el-radio-group>
								</el-form-item>
								<!-- 单选 -->
								<div class="tance" v-show="single">
									<div class="fortify">
										<div class="subs">选项内容</div>
										<div class=""><el-button class="shallow" type="primary"
												style="width: 90px;height: 30px;font-size: 16px;" size="small"
												@click="newly(index)"><el-icon class="cancel">
													<CirclePlus />
												</el-icon>新增</el-button></div>
									</div>
									<div class="circus" v-for="(it,index) in item.option" :key="index">
										<el-input v-model="it.name" class="w-50 m-2" size="large"
											placeholder="请填写选项内容" />

										<!-- 是否正确答案 -->
										<el-select v-show="form.pattern==2" class="correct" v-model="it.isCorrectAnswer"
											placeholder="是否正确答案" size="medium">
											<el-option label="是" value="1"
												style="width:100%; text-align-last: center;"></el-option>
											<el-option label="否" value="2"
												style="width:100%; text-align-last: center;"></el-option>
										</el-select>

										<el-button size="default" @click="away(item.option,index)" class="red"><el-icon
												class="cancel">
												<Delete />
											</el-icon>删除</el-button>
									</div>
								</div>
								<!-- 多选 -->

								<div class="tance" v-show="ltiterm">
									<div class="fortify">
										<div class="subs">选项内容</div>
										<div class=""><el-button type="primary"
												style="width: 90px;height: 30px;font-size: 16px;" size="small"
												@click="newlys(index)"><el-icon class="cancel">
													<CirclePlus />
												</el-icon>新增</el-button></div>
									</div>
									<div class="circus" v-for="(it,index) in item.option" :key="index">
										<el-input v-model="it.name" class="w-50 m-2" size="large"
											placeholder="请填写选项内容" />

										<!-- 是否正确答案 -->
										<el-select v-show="form.pattern==2" class="correct" v-model="it.isCorrectAnswer"
											placeholder="是否正确答案" size="medium">
											<el-option label="是" value="1"
												style="width:100%; text-align-last: center;"></el-option>
											<el-option label="否" value="2"
												style="width:100%; text-align-last: center;"></el-option>
										</el-select>

										<el-button size="default" @click="aways(item.option,index)" class="red"><el-icon
												class="cancel">
												<Delete />
											</el-icon>删除</el-button>
									</div>
								</div>


								<!-- 简答题 -->
								<div class="tance" v-show="answer">
									<div class="fortify">
										<div class="subs">是否必填</div>
										<el-select v-model="item.isRequired" placeholder="请选择" size="medium">
											<el-option label="是" value="1"
												style="width:100%; text-align-last: center;"></el-option>
											<el-option label="否" value="2"
												style="width:100%; text-align-last: center;"></el-option>
										</el-select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-form>
			</div>
			<el-button class="button" type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		ref,
		onMounted,
		reactive
	} from 'vue'
	import {
		Plus,
		CirclePlus,
		Delete
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		DTadd,
		Zzlist
	} from '../../utils/api'
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	import {
		ElMessage
	} from "element-plus";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	export default {
		name: "wjadd",
		components: {
			Plus,
			CirclePlus,
			Delete
		},
		setup() {
			const route = useRoute();
			const qingkong = ref('')
			// 选择组织搜索框变化
			let numerical = (e) => {
				if (e == '') {
					Hcode.value = ''
				}
			}
			// 组织列表接口
			const lang = () => {
				Zzlist().then((res) => {
					console.log(res)
					texture.value = res.data.data.wholeUnionList
				})
			}
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				form.Mars = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 搜索组织弹窗
			let visible = ref(false)
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}




			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),
			]
			// 获取图片地址
			let dizhi = ref('')
			// 上传图片成功回调
			let succ = (res) => {
				dizhi.value = res.data
				console.log(res)
			}
			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 简答题选项显示隐藏
			const answer = ref(false)
			// 多选内框显示隐藏
			const ltiterm = ref(false)

			// 单选内容框显示隐藏
			const single = ref(true)
			// 单选按钮删除禁用
			const Choice = ref(true)
			const Choices = ref(true)
			// 单选问题选项删除
			const away = (it, index) => {
				console.log('单选')
				it.splice(index, 1)
				if (it.length == 0) {
					single.value = true
				}
				if (it.length == 1) {
					Choice.value = true
				}
				console.log(it)
			}
			const aways = (it, index) => {
				console.log('多选')
				it.splice(index, 1)
				if (it.length == 0) {
					single.value = true
				}
				if (it.length == 1) {
					Choices.value = true
				}
				console.log(it)
			}
			const newlys = (index) => {
				console.log('多选')
				topic.value[index].option.push({
					name: ''
				})
				if (topic.value[index].option.length > 1) {
					Choices.value = false
				}
				console.log(topic.value[index].option)
			}
			// 单选问题选项input增加
			const newly = (index) => {
				console.log(form.pattern)
				topic.value[index].option.push({
					name: '',
					isCorrectAnswer: ''
				})
				if (topic.value[index].option.length > 1) {
					Choice.value = false
				}
				console.log(topic.value[index].option)
			}
			// 问卷类型选中
			const survey = (value) => {
				if (value == 1) {
					topic.value[jieshou.value].option = []
					single.value = true
					ltiterm.value = false
					answer.value = false
				} else if (value == 2) {
					topic.value[jieshou.value].option = []
					ltiterm.value = true
					single.value = false
					answer.value = false
				} else if (value == 3) {
					topic.value[jieshou.value].option = []
					ltiterm.value = false
					single.value = false
					answer.value = true
				}
			}
			const jieshou = ref(null)
			const dianji = (index) => {
				jieshou.value = index
				// console.log(topic.value[index].option)
				// topic.value[index].option={name:''}
			}
			// 删除按钮禁用
			// const forbidden=ref(true)
			// 删除答题问卷内容
			const expurgate = (index) => {
				console.log(index)
				topic.value.splice(index, 1)
				console.log(topic.value)
			}
			// 新增答题问卷内容
			const copyreader = () => {
				topic.value.push({
					name: '',
					type: '1',
					isRequired: '1',
					option: []
				})
			}
			// 是否必填
			let annexing = ref(1)
			// 答题问卷内容
			const topic = ref([{
				name: '',
				type: '1',
				isRequired: '1',
				option: [],
			}])
			// 选择类型
			// const radio1 =ref()
			// 时间选择
			const value1 = ref([
				new Date(2000, 10, 10, 10, 10),
				new Date(2000, 10, 11, 10, 10),
			])
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 上传图片
			const styA = ref(0)
			const change = (res, fileList) => {
				console.log(form.breviary)
				console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 950).then((res) => {
						console.log(res);
						resolve(res);
					});
				})
			}
			// 上传失败
			const bedefeated = (err) => {
				console.log(err, '失败')
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {
				// over.value=''
				// begin.value=''
				// console.log('标题',form.name)
				// console.log('说明',form.desc)
				// console.log('可见范围',form.sex)
				// console.log('是否启用',form.enable)
				// console.log('图片地址',dizhi.value)
				// console.log('题',topic.value)
				// console.log('问卷模式',form.pattern)
				// console.log('是否配合健步',form.strides)
				// console.log('达标步数',form.reach)
				// console.log('达标题数',form.problem)
				// console.log('单次题数',form.einmal)
				// console.log('组织code',Hcode.value)
				// console.log('是否随机出题',form.random)
				// console.log('限制每日答题次数',form.restrictive)
				// console.log('是否展示小程序端排行',form.exhibition)
				// console.log('是否展示小程序端历史记录',form.record)
				// console.log('每日答题次数',form.frequency)
				// 开始时间
				var b = ref(new Date(form.value1[0]))
				var begin = ref('')
				begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value.getDate() + ' ' +
					b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value.getSeconds();
				var start = ref(new Date(begin.value).getTime() / 1000)
				console.log('开始时间', start.value)
				// 截至时间
				var d = ref(new Date(form.value1[1]))
				var finish = ref('')
				finish.value = d.value.getFullYear() + '-' + (d.value.getMonth() + 1) + '-' + d.value.getDate() + ' ' +
					d.value.getHours() + ':' + d.value.getMinutes() + ':' + d.value.getSeconds();
				var over = ref(new Date(finish.value).getTime() / 1000)
				ruleFormRef.value.validate((valid) => {
					if (valid == true) {
						ConfirmLoading.value = true
						let data = {
							isRandomlyQuestions: form.random,
							isDayAnswer: form.restrictive,
							dayAnswer: form.frequency,
							isRanking: form.exhibition,
							isLog: form.record,
							unionCode: Hcode.value,
							title: form.name,
							startTime: start.value,
							endTime: over.value,
							isEnable: form.enable,
							rule: form.desc,
							seeing: form.sex,
							imageUrl: dizhi.value,
							content: topic.value,
							category: form.pattern,
							isSetTime: form.isSetTime,
							setTime: form.setTime,
							isWalk: form.strides,
							stepsNumber: form.reach,
							answerStandard: form.problem,
							questionsNumber: form.einmal
						}
						DTadd(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									form.name = ''
									form.desc = ''
									form.sex = ''
									form.enable = ''
									dizhi.value = ''
									topic.value = [{
										name: '',
										type: '1',
										isRequired: '1',
										option: [],
									}]
									form.pattern = ''
									form.strides = ''
									form.reach = ''
									form.problem = ''
									form.einmal = ''
									Hcode.value = ''
									form.Mars = ''
									form.random = ''
									form.restrictive = ''
									form.exhibition = ''
									form.record = ''
									form.frequency = ''
									form.value1 = ''
									qingkong.value.clearFiles()
									styA.value = 0
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'answer',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								})
							}else{
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								ConfirmLoading.value = false
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
				})
			}


			// 模拟 ajax 异步获取内容
			onMounted(() => {
				lang()
			})

			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				names: "",
				name: "",
				sex: "",
				desc: "",
				value1: "",
				enable: "",
				radio1: [],
				type: 1,
				pattern: '',
				// 每日答题次数
				frequency: '',
				// 小程序端是否展示历史记录
				record: '',
				// 小程序端展示排行
				exhibition: '',
				// 限制每日答题次数
				restrictive: '',
				// 是否随机出题
				random: '',
				// 健步配合
				strides: '',
				// 达标步数
				reach: '',
				// 达标题数
				problem: '',
				// 单次题数
				einmal: ''
			})
			// 验证表单提示
			const rules = {
				pattern: [{
					required: true,
					message: "请选择问卷模式",
					trigger: "change"
				}, ],
				enable: [{
					required: true,
					message: "请选择是否启用",
					trigger: "change"
				}, ],
				value1: [{
					required: true,
					message: "请选择时间",
					trigger: "change"
				}, ],
				desc: [{
					required: true,
					message: "请填写说明",
					trigger: "change"
				}, ],
				name: [{
					required: true,
					message: "请填写标题",
					trigger: "change"
				}, ],
				sex: [{
					required: true,
					message: "请选择分类",
					trigger: "change"
				}, ],
				Mars: [{
					required: true,
					message: "请选择组织",
					trigger: "change"
				}, ],
				isSetTime: [{
					required: true,
					message: "请选择时间限制",
					trigger: "change"
				}, ],
				setTime: [{
					required: true,
					message: "请填写答题时间",
					trigger: "change"
				}, ],
			}
			return {
				qingkong,
				// 选择弹窗值
				numerical,
				ability,
				// 弹窗
				dendrogram,
				defaultProps,
				texture,
				// 选择组织弹窗
				multiple,
				// 组织弹窗
				visible,
				defaultTime2,
				annexing,
				Choices,
				aways,
				newlys,
				// 图片上传成功回调
				succ,
				// 请求头
				header,
				// 简答题显示隐藏
				answer,
				//  单选 多选 简答点击事件
				dianji,
				// 单选 多选 简答隐藏显示
				single,
				ltiterm,
				// 单选删除禁用
				Choice,
				// 删除问卷内容
				away,
				// 问卷选项内容增加
				newly,
				// 问卷类型选择
				survey,
				// 按钮禁用
				// forbidden,
				// 删除答题问卷
				expurgate,
				// 新增答题问卷
				copyreader,
				// 问卷循环
				topic,
				// 选项类型
				// radio1,
				value1,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				dialogVisible,
				dialogImageUrl,
				onpreview,
				beforeremove,
				Remove,
				styA,
				change,
				bedefeated,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				mode: 'default',
				// 提交按钮
				onSubmit,
				// 标题验证
				ruleFormRef,
				form,
				rules
			}
		}
	}
</script>
<style lang='scss' scoped>
	.shallow {
		color: #ffffff;
		border: none;
		--el-button-bg-color: #00BFFF;
		--el-button-hover-bg-color: #87CEFA;
		--el-button-hover-text-color: #ffffff;
	}

	.shallow:hover {
		color: #ffffff;
	}

	.correct {
		margin-left: 10px;
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	.aaaass {
		width: 18.5%;
	}

	.button {
		margin-left: 6.5%;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .fortify {
		display: flex;
		align-items: center;
	}

	::v-deep .subs {
		font-size: 14px;
		color: var(--el-text-color-regular);
		margin-left: 2px;
		margin-right: 10px;
	}

	::v-deep .tance {
		display: flex;
		flex-direction: column;
		margin-left: 11px;
	}

	::v-deep .twist {
		margin-left: 13px;
		margin-top: 8px;
		margin-bottom: 20px;
	}

	::v-deep .dati {
		font-weight: bold;
		width: 76px;
		/* float: right; */
		display: flex;
		font-size: 14px;
		justify-content: flex-end;
		margin-top: 13px;
		color: var(--el-text-color-regular);

		span {
			color: var(--el-color-danger);
			margin-right: 3px;
		}
	}

	::v-deep .sheet {
		display: flex;
	}

	::v-deep .gao {
		height: 10px;
	}

	::v-deep .red,
	.red:focus:not(.red:hover) {
		color: red;
	}

	::v-deep .red {
		font-size: 20px;
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	::v-deep .red:hover {
		color: #fab6b6;
	}

	::v-deep .bulur,
	.bulur:focus:not(.bulur:hover) {
		color: #409eff;
	}

	::v-deep .bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	::v-deep .bulur:hover {
		color: #a0cfff;
	}

	::v-deep .cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	::v-deep .circus {
		display: flex;
		align-items: center;
		width: 100%;
		// margin-bottom: 10px;
		margin-top: 10px;
	}

	::v-deep .circu {
		display: flex;
		align-items: center;
		width: 100%;
	}

	::v-deep .lation {
		width: 100%;
	}

	::v-deep .shuomings {
		width: 50%;
		margin-bottom: 50px;
	}

	::v-deep .shuoming {
		width: 100%;
	}

	::v-deep .el-textarea {
		width: 50%;
	}

	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		margin-top: 10px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 80px !important;
		font-weight: bold;
	}
</style>